import React from 'react';
import {
  Header,
  Title,
  ServiceItems,
  Why,
  Thoughts,
  Team,
  ContactUs,
  Footer,
  Background,
} from '@components';
import { Layout } from '@common';

const IndexPage = () => (
  <Layout>
    <Header />
    <Title />
    <ServiceItems />
    <Why />
    <Thoughts />
    <Team />
    <ContactUs />
    <Footer />
    <Background />
  </Layout>
);

export default IndexPage;
