import React from 'react';
import { ReactComponent as Fill_1 } from '@images/art/Fill_1.svg';
import { ReactComponent as Fill_2 } from '@images/art/Fill_2.svg';
import { ReactComponent as Fill_3 } from '@images/art/Fill_3.svg';
import { ReactComponent as Fill_4 } from '@images/art/Fill_4.svg';
import { ReactComponent as Fill_5 } from '@images/art/Fill_5.svg';
import { ReactComponent as Fill_6 } from '@images/art/Fill_6.svg';
import { ReactComponent as Fill_7 } from '@images/art/Fill_7.svg';
import { ReactComponent as PhoneWhite } from '@images/art/phoneWhite.svg';

import {
  StyledBackground,
  StyledFill1Box,
  StyledFill2Box,
  StyledBallBox,
  StyledBall,
  StyledBallShadow,
  StyledFill3Box,
  StyledBallGroupBox,
  Styled1Ball,
  Styled2Ball,
  Styled3Ball,
  Styled4Ball,
  Styled5Ball,
  Styled6Ball,
  Styled7Ball,
  StyledFill4Box,
  StyledSecondBg,
  StyledFill5Box,
  StyledFill6Box,
  StyledIphoneWhiteBox,
  StyledFill7Box,
} from './StyledBackground';
const Background = () => {
  return (
    <StyledBackground>
      <StyledFill1Box>
        <Fill_1 />
      </StyledFill1Box>
      <StyledFill2Box>
        <Fill_2 />
      </StyledFill2Box>
      <StyledBallBox>
        <StyledBall />
        <StyledBallShadow />
      </StyledBallBox>
      <StyledFill3Box>
        <Fill_3 />
      </StyledFill3Box>
      <StyledBallGroupBox>
        <div>
          <Styled1Ball />
          <Styled2Ball />
          <Styled3Ball />
          <Styled4Ball />
          <Styled5Ball />
          <Styled6Ball />
          <Styled7Ball />
        </div>
      </StyledBallGroupBox>
      <StyledFill4Box>
        <Fill_4 />
      </StyledFill4Box>
      <StyledSecondBg />
      <StyledFill5Box>
        <Fill_5 />
      </StyledFill5Box>
      <StyledFill6Box>
        <Fill_6 />
      </StyledFill6Box>
      <StyledIphoneWhiteBox>
        <PhoneWhite />
      </StyledIphoneWhiteBox>
      <StyledFill7Box>
        <Fill_7 />
      </StyledFill7Box>
    </StyledBackground>
  );
};

export default Background;
