import React from 'react';
import { StyledLogoIcon, StyledLogoBox } from './StyledHeader';
const Header = () => {
  return (
    <StyledLogoBox>
      <StyledLogoIcon />
    </StyledLogoBox>
  );
};

export default Header;
