import React from 'react';
import styled from 'styled-components';
import BG_bottom from '@images/art/BG_bottom.svg';
import Stroke_1 from '@images/art/Stroke_1-1.svg';
import { ReactComponent as Path } from '@images/art/Path.svg';
import { ReactComponent as Stroke_3 } from '@images/art/Stroke_3.svg';
export const StyledContentBox = styled.div`
  margin-top: 77px;
  padding-top: 123px;
  p {
    color: white;
  }
  @media (max-width: ${props => props.theme.screen.l}) {
    margin-top: 60px;
    padding-top: 70px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 13px;
    padding: 67px 33px;
  }
`;

export const StyledContentTitle = styled.div`
  position: relative;
  text-align: center;
  max-width: 410px;
  margin: 0 auto;
  p {
    font-size: 16px;
  }
  ::after {
    content: '';
    display: block;
    width: 40px;
    height: 1px;
    background: white;
    margin: 40px auto;
    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 16px auto 42px;
    }
  }
`;
export const StyledPath = styled(Path)`
  position: absolute;
  right: -45px;
  bottom: 35px;
  @media (max-width: ${props => props.theme.screen.md}) {
    right: 10px;
    bottom: 0px;
  }
`;
export const StyledBackground = styled.div`
  background-image: url(${BG_bottom}), url(${Stroke_1});
  background-position: center 41px, -15vw 0;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: ${props => props.theme.screen.l}) {
    background-position: center 41px, calc(50vw - 760px) -15px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    background-position: calc(50vw - 750px) 33px, calc(50vw - 660px) -15px;
  }
`;
export const StyledContentForm = styled.form`
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  label {
    color: white;
  }
`;
export const StyledStroke3 = styled(Stroke_3)`
  position: absolute;
  top: -35px;
  left: -35px;
  @media (max-width: ${props => props.theme.screen.md}) {
    top: -50px;
    left: -20px;
  }
`;
export const StyledFormSingleBox = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledFormSingle = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 30px;
  label {
    margin-bottom: 8px;
    span {
      display: inline-block;
      margin-left: 4px;
      color: #ff2828;
    }
  }
  input,
  textarea {
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
  }
  textarea {
    padding: 14px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const StyledFormContent = styled(StyledFormSingle)`
  width: 100%;
`;
export const StyledSubmitBox = styled.div`
  text-align: center;
`;
export const StyledSubmit = styled(({ loading, ...props }) => (
  <button {...props} />
))`
  margin: 30px auto 168px;
  padding: 8px 50px;
  border-radius: 4px;
  background: ${({ theme, loading }) =>
    loading ? '#e8e8e8' : theme.color.blue.regular};
  color: ${({ loading }) => (loading ? '#afafaf' : 'white')};
  letter-spacing: 1px;
  cursor: ${({ loading }) => (loading ? 'not-allowed' : 'pointer')};
  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 14px auto 56px;
  }
`;
