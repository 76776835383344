import styled from 'styled-components';
import { StyledContainer } from '@components';
import { ReactComponent as Path } from '@images/art/Path.svg';
import { ReactComponent as Stroke_3 } from '@images/art/Stroke_3.svg';
export const StyledTitleBox = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 13% 276px;
  @media (max-width: ${props => props.theme.screen.lg}) {
    margin: 0 auto 276px;
    padding: 0 3%;
  }
  @media (max-width: ${props => props.theme.screen.l}) {
    align-items: flex-start;
    margin: 0 auto 70px;
  }
  @media (max-width: ${props => props.theme.screen.ml}) {
    margin-top: 20px;
    padding: 0 4% 0 6%;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
    margin: 0 auto 40px;
    padding: 0 11px 0 30px;
  }
`;

export const StyledImgBox = styled.div`
  max-width: 633px;
  width: 100%;
  @media (max-width: ${props => props.theme.screen.lg}) {
    max-width: 515px;
  }
  @media (max-width: ${props => props.theme.screen.l}) {
    max-width: 400px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-left: auto;
    max-width: 80%;
    margin-top: 30px;
  }
`;

export const StyledTitle = styled.div`
  position: relative;
  h2 {
    letter-spacing: 3px;
    margin-bottom: 12px;
  }
  span {
    display: block;
  }
  h3,
  h4 {
    line-height: 1.5;
  }
  @media (max-width: ${props => props.theme.screen.l}) {
    margin-top: 20px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 0;
    h2 {
      margin: 8px 0 4px;
    }
  }
`;

export const StyledPath = styled(Path)`
  position: absolute;
  top: -20px;
  right: 6px;
  @media (max-width: ${props => props.theme.screen.lg}) {
    top: -20px;
    right: -35px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    top: 0;
    right: -35px;
  }
`;

export const StyledStroke3 = styled(Stroke_3)`
  position: absolute;
  bottom: 0px;
  right: 70px;
  @media (max-width: ${props => props.theme.screen.lg}) {
    bottom: -19px;
    right: 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    bottom: -3px;
    right: 0px;
  }
`;
