import styled from 'styled-components';

export const StyledContainer = styled.section`
  max-width: 1140px;
  position: relative;
  margin: 0 auto;
  margin-top: ${({ mt }) => (mt ? mt : 0)};
  @media (max-width: ${props => props.theme.screen.l}) {
    margin-top: ${({ lMt }) => (lMt ? lMt : '120px')};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: ${({ mdMt }) => (mdMt ? mdMt : '60px')};
  }
`;
export const StyledTitle = styled.div`
  h2,
  h3,
  h4 {
    text-align: center;
  }
  h3,
  h4 {
    margin-top: 12px;
  }
`;
