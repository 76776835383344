import React from 'react';
import { StyledContainer, StyledTitle } from '@components';
import { StyledThoughtsItem } from './StyledThoughts';
const Thoughts = () => {
  return (
    <StyledContainer mt="200px">
      <StyledTitle>
        <h2>Our thoughts</h2>
        <h4>我們的理念</h4>
      </StyledTitle>
      <StyledThoughtsItem>
        <h4>
          邀請追求最高品質的你
          <br />
          一起合作吧！
        </h4>
        <div></div>
        <p>
          十六進位不同於以往傳統接案公司，沒有辦公室，降低並壓縮公司成本費用，反將成本利潤分配給合作夥伴，我們相信推動工程師的不是夢想，是錢，所以讓工程師及設計師收入最大化，讓工程師、設計師願意花更多心力在產品上，最佳化產品的品質。
        </p>
      </StyledThoughtsItem>
    </StyledContainer>
  );
};

export default Thoughts;
