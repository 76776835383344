import styled from 'styled-components';

export const StyledBackground = styled.div`
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 100px;
`;
export const StyledFill1Box = styled.div`
  overflow: hidden;
  grid-column: 3 / 13;
  grid-row: 1 / 13;
  svg {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    right: -12px;
  }
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-column: 2 / 13;
    grid-row: 1 / 10;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-column: 1 / 13;
    grid-row: 1 / 7;
  }
`;
export const StyledFill2Box = styled.div`
  overflow: hidden;
  grid-column: 1 / 13;
  grid-row: 5 / 35;
  position: relative;
  > svg {
    position: relative;
    height: 100%;
    left: 54%;
    transform: translateX(-50%);
  }
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-row: 3 / 27;
    padding-top: 1vw;
    > svg {
      left: 58%;
    }
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 3 / 19;
    > svg {
      left: 60%;
    }
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-row: 2 / 19;
    > svg {
      top: 50px;
      left: 70%;
    }
  }
`;

export const StyledBallBox = styled.div`
  grid-column: 2 / 3;
  grid-row: 8 / 9;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-row: 6 / 7;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 5 / 6;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    position: absolute;
    bottom: 50px;
  }
`;

export const StyledBall = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blue.light};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 55px;
    height: 55px;
    margin-top: 30px;
    margin-left: -17px;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    width: 27px;
    height: 27px;
  }
`;
export const StyledBallShadow = styled.div`
  width: 52px;
  height: 25px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.grey.regular};
  margin-top: 26px;
  margin-left: 9px;
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 41px;
    height: 19px;
    margin-top: 20px;
    margin-left: -10px;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    width: 20px;
    height: 9px;
    margin-top: 9px;
    margin-left: -13.5px;
  }
`;

export const StyledFill3Box = styled.div`
  grid-column: 1 / 5;
  grid-row: 17 / 23;
  z-index: 1;
  position: relative;
  top: 27px;
  svg {
    height: 100%;
    position: absolute;
    right: 0;
  }
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-column: 1 / 6;
    grid-row: 12 / 17;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 11 / 15;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-column: 1 / 10;
    grid-row: 10 / 13;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    top: -40px;
  }
`;

export const StyledBallGroupBox = styled.div`
  overflow: hidden;
  grid-column: 8 / 13;
  grid-row: 22 / 27;
  z-index: 1;
  position: relative;
  left: 3vw;
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-column: 9 / 13;
    grid-row: 16 / 21;
    left: 5vw;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 14 / 19;
    top: 40px;
    left: 14vw;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-row: 13 / 17;
    top: -40px;
    left: 6vw;
  }
`;

export const Styled1Ball = styled.div`
  position: absolute;
  z-index: 2;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  top: 182px;
  left: 150px;
  background: ${({ theme }) => theme.color.blue.veryLight};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 202px;
    height: 202px;
    top: 142px;
    left: 120px;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    width: 98px;
    height: 98px;
    top: 69px;
    left: 58px;
  }
`;
export const Styled2Ball = styled.div`
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  top: 0;
  left: 70px;
  background: ${({ theme }) => theme.color.blue.dark};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 180px;
    height: 180px;
    left: 55px;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    width: 86px;
    height: 86px;
    left: 26px;
  }
`;
export const Styled3Ball = styled.div`
  position: absolute;
  width: 186px;
  height: 186px;
  border-radius: 50%;
  top: 101px;
  left: 328px;
  background: ${({ theme }) => theme.color.blue.light};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 146px;
    height: 146px;
    top: 80px;
    left: 258px;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    width: 70px;
    height: 70px;
    top: 38px;
    left: 123px;
  }
`;
export const Styled4Ball = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 278px;
  left: 0;
  background: ${({ theme }) => theme.color.orange.regular};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 30px;
    height: 30px;
    top: 217px;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    width: 15px;
    height: 15px;
    top: 104px;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    display: none;
  }
`;
export const Styled5Ball = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 252px;
  left: 78px;
  background: ${({ theme }) => theme.color.green.regular};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    width: 20px;
    height: 20px;
    top: 197px;
    left: 61px;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    width: 10px;
    height: 10px;
    top: 98px;
    left: 30px;
  }
`;
export const Styled6Ball = styled.div`
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  top: 318px;
  left: 450px;
  background: ${({ theme }) => theme.color.blue.regular};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    display: none;
  }
`;
export const Styled7Ball = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 372px;
  left: 514px;
  background: ${({ theme }) => theme.color.green.regular};
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    display: none;
  }
`;

export const StyledFill4Box = styled.div`
  overflow: hidden;
  grid-column: 7 / 13;
  grid-row: 27 / 32;
  z-index: 1;
  position: relative;
  top: 30px;
  left: 25px;
  svg {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.screen.lg}) {
    grid-row: 27 / 32;
    top: 30px;
    left: 25px;
  }
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-row: 20 / 24;
    top: 40px;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 18 / 21;
    grid-column: 5 / 13;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-column: 1 / 13;
    grid-row: 16 / 18;
    svg {
      position: absolute;
      top: 0;
      left: -10vw;
    }
  }
`;
export const StyledSecondBg = styled.div`
  background: #f9f9f9;
  grid-column: 1 / 13;
  grid-row: 24 / 50;
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-row: 19 / 41;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 14 / 41;
  }
`;
export const StyledFill5Box = styled.div`
  overflow: hidden;
  grid-column: 5 / 13;
  grid-row: 36 / 50;
  z-index: 1;
  svg {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.screen.lg}) {
    grid-column: 6 / 13;
    grid-row: 34 / 48;
  }
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-column: 5 / 13;
    grid-row: 26 / 41;
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-row: 22 / 30;
    position: relative;
    svg {
      position: absolute;
      left: -30vw;
    }
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-row: 19 / 25;
    svg {
      left: -110vw;
    }
  }
`;
export const StyledFill6Box = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    display: block;
    grid-column: 6 / 13;
    grid-row: 31 / 36;
    overflow: hidden;
    z-index: 1;
    position: relative;
    svg {
      height: 100%;
      right: 0;
      position: absolute;
    }
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-row: 25 / 30;
  }
`;
export const StyledIphoneWhiteBox = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    display: block;
    grid-column: 1 / 13;
    grid-row: 28 / 33;
    svg {
      height: 100%;
    }
  }
`;
export const StyledFill7Box = styled.div`
  grid-column: 1 / 6;
  grid-row: 39 / 46;
  position: relative;
  svg {
    position: absolute;
    top: 50px;
    right: 50px;
  }
  @media (max-width: ${({ theme }) => theme.screen.lg}) {
    grid-row: 37 / 44;
  }
  @media (max-width: ${({ theme }) => theme.screen.l}) {
    grid-row: 28 / 35;
    svg {
      top: 70px;
      right: 35px;
    }
  }
  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-column: 1 / 7;
    grid-row: 33 / 38;
  }
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-column: 1 / 13;
    grid-row: 33 / 36;
    svg {
      height: 100%;
      top: 40px;
    }
  }
`;
