import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { StyledContainer, StyledTitle } from '@components';
import { StyledTeamContent, StyledTeamItem } from './StyledTeam';
const TEAM = [
  {
    name: '蔡世恩',
    image: '4n.png',
    role: 'co-founder',
    major: '前端工程師',
  },
  {
    name: '劉澔暐(懷恩)',
    image: 'whien.png',
    role: 'co-founder',
    major: '軟體工程師',
  },
  {
    name: '林尹麒(引擎)',
    image: 'engine.png',
    role: 'co-founder',
    major: '前端工程師',
  },
];
const TeamMember = ({ name, role, img, major }) => {
  return (
    <StyledTeamItem key={name}>
      <div>
        <Img fluid={img.childImageSharp.fluid} alt="4n" />
      </div>
      <p>
        {role} - <br />
        {major}
      </p>
      <h4>{name}</h4>
    </StyledTeamItem>
  );
};
const Team = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { sourceInstanceName: { eq: "team" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <StyledContainer mt="200px" lMt="153px" mdMt="60px">
          <StyledTitle>
            <h2>Team members</h2>
            <h4>團隊成員</h4>
          </StyledTitle>
          <StyledTeamContent>
            {TEAM.map(({ name, image, role, major }) => {
              const img = findTargetImageFileFrom(data, image);
              return (
                <TeamMember
                  name={name}
                  role={role}
                  img={img}
                  major={major}
                  key={image}
                />
              );
            })}
          </StyledTeamContent>
        </StyledContainer>
      )}
    />
  );
};

export default Team;
const findTargetImageFileFrom = (data, targetImagePath) =>
  data.allFile.edges.find(({ node }) => node.relativePath === targetImagePath)
    .node;
