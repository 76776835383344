const theme = {
  font: {
    primary: `'Noto Sans TC', serif`,
    secondary: `'Noto Sans TC', serif`,
    number: `Poppins, serif`,
  },
  font_size: {
    xSmall: 'font-size: 12px',
    small: 'font-size: 14px',
    regular: 'font-size: 16px',
    middle: 'font-size: 18px',
    px13: 'font-size: 13px',
    px17: 'font-size: 17px',
    px20: 'font-size: 20px',
    px24: 'font-size: 24px',
    px28: 'font-size: 28px',
    px32: 'font-size: 32px',
    px48: 'font-size: 48px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
    },
    black: {
      light: '#4a4a4a',
      regular: '#151923',
    },
    blue: {
      veryLight: '#7094ff',
      light: '#86a1cb',
      regular: '#4a90e2',
      dark: '#023c6f',
    },
    green: {
      regular: '#56d7c1',
    },
    orange: {
      regular: '#ffb12f',
    },
    grey: {
      regular: '#bcc0c8',
    },
  },
  screen: {
    sm: '480px',
    md: '767px',
    ml: '1079px',
    l: '1279px',
    lg: '1439px',
  },
};

export default theme;
