import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import {
  StyledTitleBox,
  StyledImgBox,
  StyledTitle,
  StyledPath,
  StyledStroke3,
} from './StyledTitle';
const Title = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "index_pic.png" }) {
            childImageSharp {
              fluid(maxWidth: 633) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <StyledTitleBox>
          <StyledTitle>
            <h2>十六進位有限公司</h2>
            <h3>
              十六進位追求<span>客戶獲得最高品質的程式</span>
              <span>工程師獲得成長的機會</span>
            </h3>
            <StyledPath />
            <StyledStroke3 />
          </StyledTitle>
          <StyledImgBox>
            <Img fluid={data.file.childImageSharp.fluid} alt="pc" />
          </StyledImgBox>
        </StyledTitleBox>
      )}
    />
  );
};

export default Title;
