import styled from 'styled-components';

export const StyledWhyItem = styled.div`
  background: ${({ theme }) => theme.color.white.regular};
  border-radius: 20px;
  box-shadow: 10px 10px 25px 0 rgba(92, 92, 92, 0.2);
  padding: 30px;
  margin-top: ${({ second }) => (second ? '80px' : '100px')};
  max-width: 460px;
  margin-left: ${({ second }) => (second ? '6%' : 'auto')};
  margin-right: ${({ second }) => (second ? 'auto' : '6%')};
  h2 {
    font-weight: 700;
    font-family: ${({ theme }) => theme.font.number};
    color: ${({ theme, second }) =>
      second ? theme.color.green.regular : theme.color.orange.regular};
    letter-spacing: 6px;
  }
  h4 {
    font-weight: 500;
    margin-top: 1rem;
  }
  p {
    margin-top: 1rem;
    line-height: 1.7;
  }
  @media (max-width: ${props => props.theme.screen.l}) {
    margin-top: 40px;
    h2 {
      ${({ theme }) => theme.font_size.px48};
    }
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    max-width: 250px;
    h2 {
      ${({ theme }) => theme.font_size.px32};
    }
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 20px;
  }
`;
