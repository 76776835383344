import styled from 'styled-components';
export const StyledThoughtsItem = styled.div`
  background: ${({ theme }) => theme.color.white.regular};
  border-radius: 20px;
  box-shadow: 10px 10px 25px 0 rgba(92, 92, 92, 0.2);
  padding: 40px 68px;
  max-width: 730px;
  margin: 80px auto 0;
  h4 {
    font-weight: 700;
    text-align: center;
    letter-spacing: 1.5px;
    br {
      display: none;
    }
  }
  div {
    width: 80px;
    height: 4px;
    background: ${({ theme }) => theme.color.blue.regular};
    margin: 24px auto 32px;
  }
  p {
    line-height: 1.7;
  }
  @media (max-width: ${props => props.theme.screen.lg}) {
    margin: 40px auto 0;
    width: calc(100% - 200px);
    h4 {
      letter-spacing: 1.25px;
    }
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 22px auto 0;
    width: calc(100% - 40px);
    padding: 20px;
    h4 {
      padding: 0 20px;
      letter-spacing: 1.13px;
    }
    p {
      ${({ theme }) => theme.font_size.px13};
    }
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    h4 {
      line-height: 1.5;
      br {
        display: inline;
      }
    }
  }
`;
