import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import validateEmail from 'validator/lib/isEmail';
import { StyledContainer, StyledTitle } from '@components';
import {
  StyledContentBox,
  StyledContentTitle,
  StyledBackground,
  StyledContentForm,
  StyledPath,
  StyledStroke3,
  StyledFormSingle,
  StyledFormSingleBox,
  StyledFormContent,
  StyledSubmit,
  StyledSubmitBox,
} from './StyledContactUs';
const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = data => {
    setIsLoading(true);
    fetch('https://hex-web-backend.16n.com.tw/api/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        setIsLoading(false);
        if (res.ok) {
          alert('送出成功');
          reset();
        } else {
          alert('送出失敗');
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  };
  return (
    <Fragment>
      <StyledContainer mt="200px" mdMt="28px">
        <StyledTitle>
          <h2>Contact us</h2>
          <h4>聯絡我們</h4>
        </StyledTitle>
      </StyledContainer>
      <StyledBackground>
        <StyledContentBox>
          <StyledContentTitle>
            <StyledPath />
            <p>您好</p>
            <p>
              若您有任何問題或是想成為合作夥伴都可以透過下面表單
              與我們聯繫，約1-2個工作天內回覆您，謝謝。
            </p>
          </StyledContentTitle>
          <StyledContentForm onSubmit={handleSubmit(onSubmit)}>
            <StyledStroke3 />
            <StyledFormSingleBox>
              <StyledFormSingle>
                <label htmlFor="name">聯絡人*</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  aria-label="name"
                  placeholder="王小明"
                  required
                  {...register('name', { required: true })}
                />
              </StyledFormSingle>
              <StyledFormSingle>
                <label htmlFor="phone">聯絡電話</label>
                <input
                  aria-label="phone"
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="0912-345678"
                  {...register('phone')}
                />
              </StyledFormSingle>
            </StyledFormSingleBox>
            <StyledFormSingleBox>
              <StyledFormSingle>
                <label htmlFor="company">公司名稱</label>
                <input
                  type="text"
                  name="company"
                  aria-label="company"
                  id="company"
                  placeholder="百萬富翁股份有限公司"
                  {...register('company')}
                />
              </StyledFormSingle>
              <StyledFormSingle error={errors.email}>
                <label htmlFor="email">
                  聯絡信箱*
                  {errors.email && errors.email.type === 'validate' && (
                    <span>請填寫有效的 Email</span>
                  )}
                </label>
                <input
                  type="text"
                  name="email"
                  aria-label="email"
                  id="email"
                  placeholder="imrich@mail.com"
                  required
                  {...register('email', {
                    required: true,
                    validate: value => validateEmail(value),
                  })}
                />
              </StyledFormSingle>
            </StyledFormSingleBox>
            <StyledFormContent>
              <label htmlFor="content">聯絡內容*</label>
              <textarea
                rows="15"
                name="content"
                id="content"
                aria-label="content"
                placeholder="內容內容"
                {...register('content', { required: true })}
                required
              />
            </StyledFormContent>
            <StyledSubmitBox>
              <StyledSubmit type="submit" loading={isLoading}>
                確認送出
              </StyledSubmit>
            </StyledSubmitBox>
          </StyledContentForm>
        </StyledContentBox>
      </StyledBackground>
    </Fragment>
  );
};

export default ContactUs;
