import React from 'react';
import { StyledContainer, StyledTitle } from '@components';
import {
  StyledServiceItemsContent,
  StyledServiceItem,
} from './StyledServiceItems';
const ServiceItems = () => {
  return (
    <StyledContainer>
      <StyledTitle>
        <h2>What we can do</h2>
        <h4>服務項目</h4>
      </StyledTitle>
      <StyledServiceItemsContent>
        <StyledServiceItem>
          <h4>網頁前端</h4>
          <p>從 React 或 Vue 或單純的 HTML。</p>
        </StyledServiceItem>
        <StyledServiceItem>
          <h4>後端</h4>
          <p>包含 node.js、PHP、Python、Java。</p>
        </StyledServiceItem>
        <StyledServiceItem>
          <h4>整合服務</h4>
          <p>製作單頁式應用、一頁式網站、部落格、形象官網、CRM 系統</p>
        </StyledServiceItem>
      </StyledServiceItemsContent>
    </StyledContainer>
  );
};

export default ServiceItems;
