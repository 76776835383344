import styled from 'styled-components';

export const StyledServiceItemsContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  @media (max-width: ${props => props.theme.screen.ml}) {
    padding: 0 50px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0 38px;
  }
`;
export const StyledServiceItem = styled.div`
  background: ${({ theme }) => theme.color.white.regular};
  border-radius: 20px;
  box-shadow: 10px 10px 25px 0 rgba(92, 92, 92, 0.2);
  padding: 30px;
  width: 30%;
  h4 {
    font-weight: 500;
  }
  p {
    margin-top: 8px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    margin-bottom: 16px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 20px;
  }
`;
