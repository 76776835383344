import React from 'react';
import { StyledTitle, StyledContainer } from '@components';
import { StyledWhyItem } from './StyledWhy';
const Why = () => {
  return (
    <StyledContainer mt="200px">
      <StyledTitle>
        <h2>Why us</h2>
        <h4>選擇十六進位的理由</h4>
      </StyledTitle>
      <StyledWhyItem>
        <h2>-01</h2>
        <h4>交付您不再擔憂的網頁應用產品</h4>
        <p>
          我們瞭解想要找到值得長期託付的網頁應用開發團隊非常困難，十六進位要求工程師交付的網站程式碼品質要達到最高標準。這代表我們的產品更適合長期維護、讓未來接手的團隊更加容易修改。
        </p>
      </StyledWhyItem>
      <StyledWhyItem second>
        <h2>-02</h2>
        <h4>協助您打造更加實際的產品</h4>
        <p>
          十六進位開發團隊有數位豐富開發經驗的工程師負責幫您監督，且提供您實際開發上的顧問服務，幫您釐清真正的目標以及打造真正能夠幫助您達成目標的產品。
        </p>
      </StyledWhyItem>
    </StyledContainer>
  );
};

export default Why;
