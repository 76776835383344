import React from 'react';
import { StyledFooter } from './StyledFooter';
const date = new Date();
const Footer = () => {
  return (
    <StyledFooter>
      Copyright ©{date.getFullYear()} Hexadecimal Industrial Networking Corp.
      All Rights Reserved.
    </StyledFooter>
  );
};

export default Footer;
