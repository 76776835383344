import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: '十六進位追求，客戶獲得最高品質的程式，工程師獲得成長的機會！',
  title: '十六進位 Hexadecimal',
  url: 'https://16n.com.tw',
  author: 'Hexadecimal company',
  keywords: ['十六進位', '十六進位有限公司', '外包', '前端'],
  twitterId: '',
  facebookId: '',
  imageType: 'image/png',
  image: 'https://upload.cc/i1/2020/07/12/Wfi8az.png',
};

const SEO = () => {
  return (
    <Helmet>
      {/* <meta property="fb:app_id" content={SEO_DATA.facebookId} /> */}
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.image} />
      <meta property="og:image:type" content={SEO_DATA.imageType} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={SEO_DATA.twitterId} /> */}
      <meta name="twitter:site" content={SEO_DATA.url} />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.image} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="zh-TW" />
    </Helmet>
  );
};

export default SEO;
