import styled from 'styled-components';
export const StyledTeamContent = styled.div`
  max-width: 980px;
  margin: 120px auto 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.screen.lg}) {
    margin: 40px auto 0;
    padding: 0 6%;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledTeamItem = styled.div`
  background: ${({ theme }) => theme.color.white.regular};
  border-radius: 20px;
  box-shadow: 10px 10px 25px 0 rgba(92, 92, 92, 0.2);
  padding: 30px;
  width: 30%;
  text-align: center;
  h4 {
    font-weight: 500;
    margin-top: 12px;
  }
  p {
    margin-top: 16px;
    br {
      display: none;
    }
  }
  div {
    max-width: 192px;
    margin: 0 auto;
  }
  @media (max-width: ${props => props.theme.screen.ml}) {
    p br {
      display: inline;
    }
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 32px;
    width: 192px;
    padding: 30px 20px;
    text-align: center;
    p {
      padding: 0 28px;
    }
  }
`;
